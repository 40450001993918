import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Filter from "./Filter.tsx";
import FAQ from "./FAQ.tsx";
import SeoArticle from "./SeoArticle.tsx";
import { useAppDispatch } from "../hooks/useAppDispatch.ts";
import { useSelector } from "react-redux";
import { RootState } from "../store/store.ts";
import { setCity, setServiceType } from "../store/slices/filterSlice.ts";
import { List, Button, Rate, Carousel } from "antd";
import Loading from "./Loading.tsx";
import { Santa } from "../types.ts";

const ObjectList: React.FC = () => {
  const dispatch = useAppDispatch();
  const cities = useSelector((state: RootState) => state.cities.cities);
  const loading = useSelector((state: RootState) => state.cities.loading);
  const { city: cityParam, serviceType: serviceTypeParam } = useParams<{
    city: string;
    serviceType: string;
  }>();
  const { items } = useSelector((state: RootState) => state.objects);
  const city = useSelector((state: RootState) => state.filters.city);
  const serviceType = useSelector(
    (state: RootState) => state.filters.serviceType
  );

  const [objects, setObjects] = useState<Santa[]>([]);
  const [selectedCityDeclension, setSelectedCityDeclension] = useState<
    string | null
  >(null);
  const [minPrice, setMinPrice] = useState<number>(0);

  useEffect(() => {
    const getObjects = async () => {
      const filteredByCity = city
        ? items.filter((object) =>
            object.cities.some((objectCity: string) =>
              objectCity.includes(city)
            )
          )
        : items;

      const filteredByDop = serviceType
        ? filteredByCity.filter((object) =>
            object.dop.some((dopItem: string) => dopItem.includes(serviceType))
          )
        : filteredByCity;

      const shuffledObjects = [...filteredByDop].sort(
        () => Math.random() - 0.5
      );
      setObjects(shuffledObjects);
    };

    const getCities = async () => {
      const selectedCity = cities.find((c) => c.name === city);
      if (selectedCity) {
        setSelectedCityDeclension(selectedCity.declension);
      }
    };

    if (cityParam) {
      const isCity = cities.some((city) => city.name === cityParam);
      const isServiceType = serviceType?.includes(cityParam);

      if (isCity) {
        dispatch(setCity(cityParam));
      } else if (isServiceType) {
        dispatch(setServiceType(cityParam));
      }
    }

    if (serviceTypeParam) {
      dispatch(setServiceType(serviceTypeParam));
    }

    getObjects();
    getCities();
  }, [cities, city, serviceType, cityParam, serviceTypeParam, items, dispatch]);

  useEffect(() => {
    if (objects.length > 0) {
      const minPriceValue = Math.min(...objects.map((item) => item.price));
      setMinPrice(minPriceValue);
    } else {
      setMinPrice(5000);
    }
  }, [objects]);

  const titleSuffix = selectedCityDeclension
    ? `${selectedCityDeclension} ${serviceType ? serviceType : ""}`.trim()
    : serviceType || "";

  useEffect(() => {
    if (!loading) {
      if (city && serviceType) {
        if (selectedCityDeclension) {
          (window as any).YandexRotorSettings.IsLoaded = true;
        } else {
          (window as any).YandexRotorSettings.IsLoaded = false;
        }
      } else if (city) {
        if (selectedCityDeclension) {
          (window as any).YandexRotorSettings.IsLoaded = true;
        } else {
          (window as any).YandexRotorSettings.IsLoaded = false;
        }
      } else if (serviceType) {
        (window as any).YandexRotorSettings.IsLoaded = true;
      } else {
        (window as any).YandexRotorSettings.IsLoaded = false;
      }
    }
  }, [city, selectedCityDeclension, serviceType, loading]);

  return (
    <div className="listing">
      <title>
        {city ? `${titleSuffix}` : `${serviceType ? ` ${serviceType}` : ""}`}
      </title>
      <meta
        name="description"
        content={
          city
            ? `Найдено ${objects.length} компаний ${titleSuffix} по цене от ${minPrice} рублей`
            : `Список всех компаний ${serviceType ? ` ${serviceType}` : ""}`
        }
      />
      <div className="listing_top">
        <div className="block filters">
          <Filter />
        </div>
        <div className="block">
          <h1 className="title">
            {city
              ? `${titleSuffix}`
              : `${serviceType ? ` ${serviceType}` : ""}`}
          </h1>
          {loading ? (
            <Loading />
          ) : (
            <List
              header={<h2 className="h3">Выберите компанию</h2>}
              bordered
              dataSource={objects}
              renderItem={(object) => (
                <div className="listing_item" key={object._id}>
                  <Carousel arrows>
                    {object.images.map((image, imageIndex) => (
                      <div key={imageIndex}>
                        <img
                          src={image}
                          alt={`${imageIndex + 1}`}
                          className="listing_item_img"
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </Carousel>
                  <div className="listing_item_content text">
                    <Link
                      to={`/company/${object._id}`}
                      className="listing_item_title"
                    >
                      <span>{object.name}</span>
                    </Link>
                    <span>Стоимость: от {object.price} руб.</span>
                    <div>
                      Рейтинг:
                      <Rate
                        disabled
                        defaultValue={object.rating}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </div>
                  <Button
                    color="primary"
                    variant="solid"
                    className="listing_item_button"
                    onClick={() => {
                      window.open(`tel:${object.telephone}`, "_blank");
                    }}
                  >
                    Позвонить
                  </Button>
                </div>
              )}
            />
          )}
        </div>
      </div>
      <SeoArticle
        location={selectedCityDeclension}
        serviceType={serviceType}
        priceFrom={minPrice}
      />

      <FAQ />
    </div>
  );
};

export default ObjectList;
